@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* import your all themes below */
  @import "styles/dark.css";
  @import "styles/light.css";

  body {
    color: var(--foreground);
    background: var(--background);

    transition-property: opacity, background, transform, filter;
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
  }
}

@layer components {
  .progress-bar {
    @apply z-40 bg-primary-500 border border-primary-500 top-16 fixed left-0 right-0 h-1;
    transform-origin: 0%;
  }

  .next,
  .prev {
    @apply absolute flex justify-center bg-secondary-100 w-10 h-10 items-center cursor-pointer font-bold z-10;
    top: calc(50% - 20px);
    border-radius: 30px;
    user-select: none;
    font-size: 18px;

  }

  .next {
    right: 10px;
  }

  .prev {
    left: 10px;
    transform: scale(-1);
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  /*
  Formateo de Markdown
   **/

  .md-content {
    @apply justify-center text-justify tracking-wide h-full py-10 leading-loose;

    p {
      @apply mb-4 text-lg py-4;

    }

    p>a {
      @apply font-medium text-primary-600;
    }

    p>a:hover {
      @apply text-primary-400 border-b-4 border-primary-400 rounded;
    }

    blockquote {
      @apply relative pt-[1em] border-t-2 mt-10 border-primary-500;

      p {
        @apply font-medium text-xl py-4;
      }
    }

  }

  .whats-btn {
    @apply bg-secondary-100 z-30 fixed items-center w-16 h-[66px] p-1 decoration-0;
    position: fixed;
    bottom: 2em;
    right: 25px;
    text-decoration: none;
    display: none;
    border-radius: 50%;

    & span {
      @apply w-[56px] h-[56px] block;
      border-radius: 50%;
      background-image: url("/images/whatsapp_45x39.png");
      background-size: 100%;
    }
  }

  .whats-btn:hover {
    @apply bg-primary-500;
  }

  .whats-btn-show {
    @apply block;
  }

  .bg-whatsapp {
    background: url('/images/background-whatsapp.jpg') center center / cover no-repeat;
  }

  /** -- Fin de Components **/
}

@layer utilities {

  .text-subtitle {
    @apply bg-gradient-to-br from-violet-400 via-violet-600 to-blue-400 bg-clip-text text-transparent capitalize font-logo text-3xl;
  }

  .home-transition {
    @apply bg-primary-400 opacity-0 rounded-2xl;

    transform: translateX(40px);
    filter: blur(12px)
  }

  .about-transition {
    @apply bg-secondary-400 opacity-0 rounded-2xl;

    transform: translateX(40px);
    filter: blur(12px)
  }

  .next-transition {
    @apply bg-secondary-400 opacity-0 rounded-2xl;

    transform: translateY(40px);
    filter: blur(12px)
  }

  .text-balance {
    text-wrap: balance;
  }

  .debug-red {
    border: solid 1px red;
  }

  .debug-blue {
    border: solid 1px blue;
  }

  .debug-yellow {
    border: solid 1px yellow;
  }

  .debug-green {
    border: solid 1px green;
  }
}